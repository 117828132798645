<template>
<Head>
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png">
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png">
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png">
      <link rel="manifest" href="/favicon/site.webmanifest">

    </Head>
  <div class="container-fluid p-10">

  <slot></slot>

  </div>
</template>
<script setup lang="ts">

import {useHMGlobalStore} from "~/composables/useHomeMasterGlobalStore";

useHead({
    titleTemplate: 'Home Mester template - %s',
  bodyAttrs: {
    class: 'font-inter, text-gray-900'
  },
  })



const { fetchGlobalData } = useHMGlobalStore();
onMounted(fetchGlobalData);

</script>